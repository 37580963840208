<template>
  <div>
    <TriggerClientList />
  </div> 
</template>
<script>
import TriggerClientList from "@/components/client/TriggerClientList.vue";

export default {
  name: "Index",
  components: {
    TriggerClientList,
  }
};
</script>
